<template src="./byremittancegateway.html"></template>
<style scoped src="./byremittancegateway.css"></style>

<script>

import accountConstants from '../../../../constants/AccountConstants.vue'
import applicationConstants from '@/constants/AppConstants.vue'
import moment from 'moment'
import reportServices from '@/services/ReportService.vue'
import Loading from 'vue-loading-overlay'
import Modal from '@/components/modal/notification/modal'
import JsonToExcel from 'vue-json-excel'
import Pagination from '@/components/vtable/vtpagination.vue'

export default {
    name: "TransactionStatisticsByRemittanceGateway",
    components: {
        Loading, Modal, JsonToExcel, Pagination
    },
    data () {
        return {
            selectedYear: "",
            selectedMonth: "",
            selectedRemittanceGateway: "",
            yearList: [],
            monthList: [],
            rgList: accountConstants.remittanceGatewayId,
            enablePaginateData: applicationConstants.enableLazyLoading,
            transResponseData: [],
            transTableDataColumns: ['Name', 'Description', 'Count', 'Total', 'Avg'],
            transTableOptions: { },
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            csvFields: {
                "Name": "Name",
                "Description": "Description",
                "Count": "Count",
                "Total":"Total",
                "Avg": "Avg"
            }
        }
    },
    methods: {
        searchTransData () {
            this.loaderVisible = true
            reportServices.methods.GetTransStatsByRemittanceGateway({
                RemittanceGateway: this.selectedRemittanceGateway ? this.selectedRemittanceGateway : null,
                Month: this.selectedMonth ? this.selectedMonth : null,
                Year: this.selectedYear ? this.selectedYear : null
            })
            .then(res => {
                this.transResponseData = res.csGetTxnStatsByRemittanceGatewayResponse.TransactionData?.RptTransactionStatsDataByRemittanceGateway
                this.transResponseData = this.transResponseData ? (Array.isArray(this.transResponseData) ? this.transResponseData : [this.transResponseData]) : [] 
                if (this.enablePaginateData) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
                this.loaderVisible = false
            })
            .catch(err => {
                this.transResponseData = []
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        responseModifier (tranasactionResponse) {
            if (tranasactionResponse) {
                tranasactionResponse.forEach(transaction => {
                    Object.keys(transaction).map(key => transaction[key] = transaction[key]._text ? transaction[key]._text : null)
                })
            }
            return tranasactionResponse ? tranasactionResponse : []
        },
        clearAll() {
            this.selectedYear = ""
            this.selectedMonth = ""
            this.selectedRemittanceGateway = ""
        },
        getYearListByRange (range) {
            return Array.from({length: range}, (v, i) => (new Date().getFullYear()) - range + i + 1)
        },
        exportTableData () {
            if (this.transResponseData.length > 0) {
                return this.responseModifier(JSON.parse(JSON.stringify(this.transResponseData)))
            }
        }
    },
    computed: {
        transTableData () {
            let transReport = []
            if(this.transResponseData && this.transResponseData.length > 0) {
                transReport = JSON.parse(JSON.stringify(this.transResponseData))
                if (this.enablePaginateData) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    transReport = transReport.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                transReport = this.responseModifier(transReport)
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.transResponseData?.length ?? 0
                    }
                }
                if (this.enablePaginateData) {
                    updateTotalRecords();
                }
            }
            return transReport
        }
    },
    mounted () {
        this.yearList = (this.getYearListByRange(23)).sort((a,b) => b - a).map(year => { return {Text: year, Value: year}})
        this.monthList = (moment.months()).map(mon => {return { Text: mon, Value: mon}})
    }
}
</script>
